<template>
  <div class="box box-primary project-companies-box" style="flex: 1;">
    <div class="block-header">
      <span class="block-header-text">主体信息</span>
    </div>
    <div class="box-body">
      <section v-if="owner&&owner.Name!=''">
        <strong><i class="fa fa-institution margin-r-5"></i> 建设单位</strong>
        <p class="text-left" style="margin: 8px 0;">
          <Poptip :title="owner.Name" trigger="hover" placement="right">
            <a>{{ owner.Name }} <i class="fa fa-arrow-circle-right"></i></a>
            <div class="api" slot="content">
              <ul>
                <li>统一社会信用代码：<a :href="'//www.riskstorm.com/company/'+owner.License" target="_blank">{{owner.License}}</a></li>
                <li>项目联系人：{{owner.PrjContactName}}</li>
                <li>联系人电话：{{owner.PrjContactNumber}}</li>
              </ul>
            </div>
          </Poptip>
        </p>
      </section>
      <section v-if="contractor&&contractor.Name!=''">
        <hr>
        <strong><i class="fa fa-sitemap margin-r-5"></i> 施工总承包单位</strong>
        <p class="text-left" style="margin: 8px 0;">
          <Poptip :title="contractor.Name" trigger="hover" placement="right" @on-popper-show="getContractorDetail(contractor.License)">
            <a>{{ contractor.Name }} <i class="fa fa-arrow-circle-right"></i></a>
            <div class="api" slot="content">
              <ul>
                <li>统一社会信用代码：<a :href="'//www.riskstorm.com/company/'+contractor.License" target="_blank">{{contractor.License}}</a></li>
                <li>项目联系人：{{contractor.PrjContactName}}</li>
                <li>联系人电话：{{contractor.PrjContactNumber}}</li>
              </ul>
              <Spin size="small" v-if="!contractorBasics"></Spin>
              <ul v-else>
                <li>注册地：{{contractorBasics.area}}</li>
                <li>注册类型：{{contractorBasics.corpType}}</li>
                <li>成立日期：{{contractorBasics.estDate}}</li>
                <li>营业执照编号：{{contractorBasics.license}}</li>
                <li>营业地址：{{contractorBasics.address}}</li>
                <li>邮编：{{contractorBasics.postCode}}</li>
                <li>法人：{{contractorBasics.legalPerson}}({{contractorBasics.legalPersonRole}})</li>
                <li>企业网址：{{contractorBasics.website}}</li>
              </ul>
            </div>
          </Poptip>
        </p>
      </section>
      <section v-if="subcontractors.length>0&&subcontractors[0].Name">
        <div v-for="(sb, idx) in subcontractors" :key="idx">
          <hr />
          <strong><i class="fa fa-wrench margin-r-5"></i> 专业分包单位{{ idx+1 }}</strong>
          <p class="text-left" style="margin: 8px 0;">
            <Poptip :title="sb.Name" trigger="hover" placement="right" @on-popper-show="getLabourCompanyDetail(idx)">
              <a>{{ sb.Name }} <i class="fa fa-arrow-circle-right"></i></a>
              <div class="api" slot="content">
                <ul>
                  <li>统一社会信用代码：<a :href="'//www.riskstorm.com/company/'+sb.License" target="_blank">{{sb.License}}</a></li>
                  <li>项目联系人：{{sb.PrjContactName}}</li>
                  <li>联系人电话：{{sb.PrjContactNumber}}</li>
                </ul>
                <Spin size="small" v-if="!subcontractorCrawlers[idx]"></Spin>
                <ul v-else>
                  <li>注册资本：{{subcontractorCrawlers[idx].regCapital}} 万元</li>
                  <li>经济性质：{{subcontractorCrawlers[idx].entType}}</li>
                  <li>成立日期：{{subcontractorCrawlers[idx].estDate}}</li>
                  <li>营业执照编号：{{subcontractorCrawlers[idx].license}}</li>
                  <li>营业地址：{{subcontractorCrawlers[idx].address}}</li>
                  <li>法人：{{subcontractorCrawlers[idx].legalPerson}}({{subcontractorCrawlers[idx].legalPersonRole}})</li>
                  <li>主项专业等级：{{subcontractorCrawlers[idx].majorQualification}}</li>
                  <li>增项专业等级：{{subcontractorCrawlers[idx].addonQualification}}</li>
                </ul>
              </div>
            </Poptip>
          </p>
        </div>
      </section>
    </div>
    <div id="test"></div>
  </div>
</template>

<script>
export default {
  name: 'project-companies-box',
  props: ['data'],
  data () {
    return {
      project: {},
      owner: {
        TypeName: '建设单位',
        Type: 1,
        Name: '',
        License: '',
        PrjContactName: '',
        PrjContactNumber: ''
      },
      contractor: {
        TypeName: '施工总承包单位',
        Type: 2,
        Name: '',
        License: '',
        PrjContactName: '',
        PrjContactNumber: ''
      },
      subcontractors: [],
      subcontractorCrawlers: [],
      apiItem: {
        apiHost: '',
        apiService: '',
        apiAction: '',
        apiQuery: {
        }
      },
      apiData: {
      },
      contractorBasics: null
    }
  },
  mounted () {
  },
  watch: {
    'data': function (val) {
      this.project = val
      this.getOwner()
      this.getContractor()
      this.getSubContractor()
    }
  },
  methods: {
    getOwner () {
      this.owner = this.project.Companies.filter(function (item) {
        return item.Type === 1
      })[0]
    },
    getContractor () {
      this.contractor = this.project.Companies.filter(function (item) {
        return item.Type === 2
      })[0]
      this.getContractorDetail()
    },
    getSubContractor () {
      this.subcontractors = this.project.Companies.filter(function (item) {
        return item.Type === 3
      })
    },
    getContractorDetail (license) {
      console.log(license)
    },
    getLabourCompanyDetail (idx) {
      let sb = this.subcontractors[idx]
      console.log(sb)
    }
  }
}
</script>

<style scoped>
hr {
  border: none;
  border-top: 1px solid #f5f5f5;
  margin: 10px 0;
}
</style>
